import * as React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import loadable from "@loadable/component"
import styled from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Panel = loadable(() => import("../components/iframe-panel"), {
  fallback: <div></div>,
})
const Video = loadable(() => import("../components/video"), {
  fallback: <div></div>,
})

const Image = styled(GatsbyImage)`
  margin: 0;
`

const Main = styled.main`
  width: 100%;

  z-index: 1;
`

const Background = styled(Panel)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  overflow: hidden;

  z-index: -1;
`

const Further = styled(Link)`
  z-index: 2;

  padding: 1em;
  margin-top: ${props => props.theme.margins.menu[0]};
  @media only screen and (min-width: ${props =>
      props.theme.breakpoints.tablet}) {
    padding: 1em;
    margin-top: ${props => props.theme.margins.menu[1]};
  }
  @media only screen and (min-width: ${props =>
      props.theme.breakpoints.computer}) {
    padding: 1em;
    margin-top: ${props => props.theme.margins.menu[2]};
  }

  background-color: ${props => props.theme.colors.menuBackground};
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.2));

  h2 {
    color: ${props => props.theme.colors.menuLink};
    text-align: center;
    margin: 0;

    font-size: ${props => props.theme.sizes.heading3[0]};
    @media only screen and (min-width: ${props =>
        props.theme.breakpoints.tablet}) {
      font-size: ${props => props.theme.sizes.heading3[1]};
    }
    @media only screen and (min-width: ${props =>
        props.theme.breakpoints.computer}) {
      font-size: ${props => props.theme.sizes.heading2[2]};
    }
  }
  &:hover {
    h2 {
      color: ${props => props.theme.colors.menuLinkHover};
    }
  }
`

const Home = ({ data }) => {
  const {
    home: { image, video, link, videoBackground },
    site: { meta },
  } = data

  return (
    <Layout>
      <Seo title={meta.title} />
      {video && !videoBackground && (
        <Main>
          <Video url={video} />
        </Main>
      )}
      {image &&
        (!video || videoBackground) &&
        (link ? (
          <Link to={link}>
            <Main>
              <Image image={image.gatsbyImageData} alt={image.title} />
            </Main>
          </Link>
        ) : (
          <Main>
            <Image image={image.gatsbyImageData} alt={image.title} />
          </Main>
        ))}
      {((video && !videoBackground) || image) && link && (
        <Further to={link}>
          <h2>Mehr Informationen</h2>
        </Further>
      )}
      {video &&
        videoBackground &&
        (link ? (
          <Link to={link}>
            <Background url={video} spinnerColor="#8EB48D" />
          </Link>
        ) : (
          <Background url={video} />
        ))}
    </Layout>
  )
}

export const query = graphql`
  {
    home: contentfulStartseite(
      node_locale: { eq: "de" }
      title: { ne: "H_PH" }
    ) {
      image {
        title
        gatsbyImageData(
          formats: [AUTO, WEBP]
          quality: 80
          width: 960
          placeholder: DOMINANT_COLOR
        )
      }
      video
      link
      videoBackground
    }
    site {
      meta: siteMetadata {
        title
      }
    }
  }
`

export default Home
